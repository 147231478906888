<template>
  <div class="rumble">
    <section class="intro">
      <div class="bg-only"></div>
      <img class="rumbleLogo" alt="Regional Rumble Logo" @click="openReg" src ="https://cms.aplesports.com/storage/uploads/2023/09/18/650841c1d9f23rr23-brand-logo-global-white.png" />
      <img class="rumbleYellowLogo" alt="Regional Rumble SSA Logo" src ="https://cms.aplesports.com/storage/uploads/2023/09/19/650957469adearr23-brand-logo-regional-ssa.png" />
      <img class="rumbleGreenLogo" alt="Regional Rumble MENA Logo" src ="https://cms.aplesports.com/storage/uploads/2023/09/19/650957436a783rr23-brand-logo-regional-mena.png" />
      <img class="rumbleRedLogo" alt="Regional Rumble Asia Logo" src ="https://cms.aplesports.com/storage/uploads/2023/09/19/650954c83f3farr23-brand-logo-regional-asia.png" />
      <img class="rlLogo" alt="Featuring RL logo" src="https://cms.aplesports.com/storage/uploads/2023/09/18/65084e280f323rl_featuringrl_logo.png">
      <div class="scroll-indicator">
        scroll&nbsp;&nbsp;
      </div>
    </section>

    <section class="selector">
      <div class="apac" @click="gotoRoute('/regionalrumble/apac')">
        <span class="unoverlay"></span>
        <img alt="Regional Rumble APAC Logo" src="https://cms.aplesports.com/storage/uploads/2023/09/18/65086296b79fcrr23-brand-logo-asia-white.png" />
      </div>
      <div class="me" @click="gotoRoute('/regionalrumble/mena')">
        <span class="unoverlay"></span>
        <img alt="Regional Rumble MENA Logo" src="https://cms.aplesports.com/storage/uploads/2023/09/18/6508629a9ba86rr23-brand-logo-mena-white.png" />
      </div>
      <div class="ssa" @click="gotoRoute('/regionalrumble/ssa')">
        <span class="unoverlay"></span>
        <img alt="Regional Rumble SSA Logo" src="https://cms.aplesports.com/storage/uploads/2023/09/18/6508629d08e60rr23-brand-logo-ssa-white.png" />
      </div>
    </section>
    <section class="footer">
      Rocket League, Psyonix, and all related marks and logos are trademarks or registered trademarks of Psyonix LLC and used under license.  &copy; 2015-2023 Licensed by Psyonix LLC, All rights reserved. All other trademarks are property of their respective owners.
    </section>
  </div>
</template>

<script>
export default {
  name: 'RegionalRumble',
  methods: {
    gotoRoute(route) {
      document.querySelector('header').classList.remove('scroll');
      this.$router.push(route);
    },
    openReg() {
      window.open('https://www.start.gg/hub/regional-rumble');
    },
  },
  mounted() {
    const h = document.querySelector('header');
    const container = document.querySelector('.rumble');
    container.addEventListener('scroll', () => {
      if(container.scrollTop > 128) {
          if(!h.classList.contains('scroll')) {
              h.classList.add('scroll')
          }
      } else {
          if(h.classList.contains('scroll')) {
              h.classList.remove('scroll')
          }
      }
    });

    const apac = document.querySelector('.apac')[0];
    const apac_overlay = document.querySelector('.apac span');
    const me = document.querySelector('.me')[0];
    const me_overlay = document.querySelector('.me span');
    const ssa = document.querySelector('.ssa')[0];
    const ssa_overlay = document.querySelector('.ssa span');

    apac_overlay.addEventListener('mouseover', () => {
      me_overlay.style['background'] = "rgba(0, 0, 0, 0.3)";
      ssa_overlay.style['background'] = "rgba(0, 0, 0, 0.3)";
    });

    apac_overlay.addEventListener('mouseout', () => {
      me_overlay.style['background'] = "rgba(0, 0, 0, 0)";
      ssa_overlay.style['background'] = "rgba(0, 0, 0, 0)";
    });

    me_overlay.addEventListener('mouseover', () => {
      apac_overlay.style['background'] = "rgba(0, 0, 0, 0.3)";
      ssa_overlay.style['background'] = "rgba(0, 0, 0, 0.3)";
    });

    me_overlay.addEventListener('mouseout', () => {
      apac_overlay.style['background'] = "rgba(0, 0, 0, 0)";
      ssa_overlay.style['background'] = "rgba(0, 0, 0, 0)";
    });

    ssa_overlay.addEventListener('mouseover', () => {
      apac_overlay.style['background'] = "rgba(0, 0, 0, 0.3)";
      me_overlay.style['background'] = "rgba(0, 0, 0, 0.3)";
    });

    ssa_overlay.addEventListener('mouseout', () => {
      apac_overlay.style['background'] = "rgba(0, 0, 0, 0)";
      me_overlay.style['background'] = "rgba(0, 0, 0, 0)";
    });
  }
}
</script>
